import $ from 'jquery';
import '@fortawesome/fontawesome-free/js/all.js';
import 'bootstrap';
import 'popper.js';
import 'jquery-ui';
import 'bootstrap-table'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import { Calendar as FullCalendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';

// Adding JQuery into the window variable for global use
window.$ = $;
window.jQuery = $;
window.FullCalendar = FullCalendar;
window.dayGridPlugin = dayGridPlugin;
window.bootstrapPlugin = bootstrapPlugin;